import { graphql, useStaticQuery } from 'gatsby';
import React, { createContext, useEffect, useState } from 'react';

const PRODUCTS_QUERY = graphql`
query {
    allShopifyProduct {
        nodes {
            handle
            title
            totalInventory
            tracksInventory
            hasOnlyDefaultVariant
            variants {
                displayName
                price
                image {
                    gatsbyImageData(aspectRatio: 0.78, width: 400)
                }
                compareAtPrice
                availableForSale
                storefrontId
                shopifyId
                selectedOptions {
                    name
                    value
                }
            }
            options {
                name
                values
            }
            images {
                gatsbyImageData(aspectRatio: 0.78, width: 400)
            }
            descriptionHtml
            shopifyId
        }
    }
}`;

const DEFAULT = { products: [] };
const ProductsContext = createContext(DEFAULT);

export function ProductsContextProvider({ children }) {

    const shopifyProductsQuery = useStaticQuery(PRODUCTS_QUERY);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        setProducts(shopifyProductsQuery.allShopifyProduct.nodes);
    }, [shopifyProductsQuery.allShopifyProduct.nodes]);

    const value = { ...DEFAULT, products };
    return (
        <ProductsContext.Provider value={value}>
            {children}
        </ProductsContext.Provider>
    );

}

export default ProductsContext;